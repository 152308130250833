import { Loader } from "../components/Loader";

import { ClosedStep } from "./StepClosed";

export function Steper() {
  const StepPage = () => {
    return <ClosedStep />;
  };

  return (
    <div
      style={{
        flex: 1,
      }}
    >
      <Loader />
      {StepPage()}
    </div>
  );
}
