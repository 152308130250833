import styled from "@emotion/styled";

const StyledBg = styled.div`
  background-image: url("/bg.jpeg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  flex: 1;
  height: 100%;
  position: relative;
`;

const StyledContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: center;
  gap: 20px;
  padding: 10px;
  font-size: 1.2rem;
  line-height: 2rem;
`;

export function ClosedStep() {
  return (
    <StyledBg>
      <StyledContent>
        <p>
          <h3>לקוחות יקרים!</h3>
          <div>אני רוצה להודות לכם על הכל!</div>
          <div>
            לצערי בתקופה האחרונה לא נכנסו מספיק הזמנות ונאלצתי לסגור את האתר עד
            להודעה חדשה!
          </div>
          <div>
            להזמנות באיסוף עצמי ניתן להגיע למפעל במושב ״עזריקם״ בתיאום מראש
            בטלפון
          </div>
          <div>
            <a
              href="tel:054-2080804"
              style={{
                color: "#fff",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
              aria-label="טלפון לשירות לקוחות"
            >
              054-2080804
            </a>
          </div>
          <div>
            להזמנות של מעל-20 מארזים לכל מקום בארץ צרו איתי קשר ישירות בטלפון
          </div>
          <div>
            <a
              href="tel:054-2080804"
              style={{
                color: "#fff",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
              aria-label="טלפון לשירות לקוחות"
            >
              054-2080804
            </a>
          </div>
          <div>מודה לכם על הכל,</div>
          <div>ציון חמוצים!</div>
        </p>
      </StyledContent>
    </StyledBg>
  );
}
